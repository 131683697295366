
// THIS FILE IS AUTOGENERATED AS PART OF THE EXTENSION AND MODE PLUGIN PROCESS.
// IT SHOULD NOT BE MODIFIED MANUALLY 
const extensions = [];
const modes = [];

// Not required any longer
window.extensions = extensions;
window.modes = modes;

extensions.push("@ohif/extension-cornerstone");
extensions.push("@ohif/extension-measurement-tracking");
extensions.push("@ohif/extension-cornerstone-dicom-sr");
extensions.push("@ohif/extension-default");
extensions.push("@ohif/extension-dicom-pdf");
extensions.push("@ohif/extension-dicom-video");
extensions.push("@ohif/extension-tmtv");
extensions.push("@ohif/extension-cornerstone-dicom-seg");
extensions.push("@better-medicine/extension-tuh-clinical-study");
modes.push("@better-medicine/test-phase");
modes.push("@better-medicine/dynamics");


// Add a dynamic runtime loader
async function loadModule(module) {
  if (typeof module !== 'string') return module;
  if( module==="@ohif/extension-cornerstone") {
    const imported = await import("@ohif/extension-cornerstone");
    return imported.default;
  }
  if( module==="@ohif/extension-measurement-tracking") {
    const imported = await import("@ohif/extension-measurement-tracking");
    return imported.default;
  }
  if( module==="@ohif/extension-cornerstone-dicom-sr") {
    const imported = await import("@ohif/extension-cornerstone-dicom-sr");
    return imported.default;
  }
  if( module==="@ohif/extension-default") {
    const imported = await import("@ohif/extension-default");
    return imported.default;
  }
  if( module==="@ohif/extension-dicom-pdf") {
    const imported = await import("@ohif/extension-dicom-pdf");
    return imported.default;
  }
  if( module==="@ohif/extension-dicom-video") {
    const imported = await import("@ohif/extension-dicom-video");
    return imported.default;
  }
  if( module==="@ohif/extension-tmtv") {
    const imported = await import("@ohif/extension-tmtv");
    return imported.default;
  }
  if( module==="@ohif/extension-cornerstone-dicom-seg") {
    const imported = await import("@ohif/extension-cornerstone-dicom-seg");
    return imported.default;
  }
  if( module==="@better-medicine/extension-tuh-clinical-study") {
    const imported = await import("@better-medicine/extension-tuh-clinical-study");
    return imported.default;
  }
  if( module==="@better-medicine/test-phase") {
    const imported = await import("@better-medicine/test-phase");
    return imported.default;
  }
  if( module==="@better-medicine/dynamics") {
    const imported = await import("@better-medicine/dynamics");
    return imported.default;
  }
  return (await import(/* webpackIgnore: true */ module)).default;
}

// Import a list of items (modules or string names)
// @return a Promise evaluating to a list of modules
export default function importItems(modules) {
  return Promise.all(modules.map(loadModule));
}

export { loadModule, modes, extensions, importItems };

